import React, { useState, useRef, useContext } from "react"
import { Link, navigate, graphql } from "gatsby"
import styled from "styled-components"
import Img from "gatsby-image/withIEPolyfill"
import { useSpring, animated } from "react-spring"
import { aspectRatio } from "../../helpers/aspect-ratio"
import Observer from "../observer"
import Button from "../button"
import { TransitionContext } from "../context/transition-context"

export const fragment = graphql`
  fragment JoinUsInterestedFragment on WordPress_Page_Joinfields {
    interestedIn {
      buttonText
      interestedTitle
      text
      interestedList {
        item
      }
      bgImage {
        sourceUrl
        imageFile {
          childImageSharp {
            fluid(maxWidth: 1200, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    }
  }
`

const JoinUsInterested = ({ interested }) => {
  const [animatedTitle, setAnimatedTitle] = useState(false)
  const [transitionActive, setTransitionActive] = useContext(TransitionContext)
  const ref = useRef()

  const titleAnimation = useSpring({
    opacity: animatedTitle ? 1 : 0,
    config: { mass: 5, tension: 500, friction: 100 },
  })

  return (
    <Outer>
      <Observer
        element={ref.current}
        toggle={animatedTitle}
        setToggle={setAnimatedTitle}
      >
        <WrapInterested ref={ref} style={titleAnimation}>
          <WrapLeft>
            <WrapText dangerouslySetInnerHTML={{ __html: interested.text }} />
            <Link
              to="/contact"
              onClick={event => {
                event.preventDefault()
                setTransitionActive(true)
                setTimeout(() => {
                  navigate(`/contact`)
                  setTransitionActive(false)
                }, 500)
              }}
            >
              <ButtonGetInTouch>Get in touch</ButtonGetInTouch>
            </Link>
          </WrapLeft>
          <WrapItems>
            <WrapText
              dangerouslySetInnerHTML={{ __html: interested.interestedTitle }}
            ></WrapText>
            <WrapList>
              {interested.interestedList.map((item, index) => (
                <Item key={item.item}>
                  <h3>{item.item}</h3>
                </Item>
              ))}
            </WrapList>
          </WrapItems>
        </WrapInterested>
      </Observer>
      <WrapImageBg>
        <BgImg
          fluid={interested.bgImage.imageFile.childImageSharp.fluid}
          loading="eager"
          backgroundColor="#ffffff"
          decoding="sync"
        />
      </WrapImageBg>
    </Outer>
  )
}

export default JoinUsInterested

const Outer = styled.div`
  position: relative;
  margin: 0 auto;
  height: 100%;
  z-index: 2;
`

const WrapInterested = styled(animated.div)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0 12.5%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  z-index: 2;

  @media (max-width: 1024px) {
    padding: 0 7.5%;
  }

  @media (max-width: 650px) {
    position: relative;
    top: unset;
    left: unset;
    transform: translate(0, 0);
    padding: 65px 3.5rem;
    height: 100%;
  }
`

const WrapImageBg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  ${aspectRatio(1440, 820)}
  z-index: -1;

  @media (max-width: 650px) {
    ${aspectRatio(428, 1037)}
    position: absolute;
    width: 100%;
    margin-bottom: 0;
    top: 0;
  }

  &::after {
    content: "";
    display: block;
  }
`

const BgImg = styled(Img)`
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const WrapList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: 100%;
`

const WrapText = styled.div`
  width: 100%;
  font-family: ${props => props.theme.fonts.sofia};
  color: ${props => props.theme.colours.white};
  font-size: 4rem;

  @media (max-width: 650px) {
    max-width: 100%;
    width: 100%;
    height: 100%;
    font-size: 7rem;
    line-height: 1.1;
    margin-bottom: 5rem;

    br {
      display: none;
    }
  }

  p {
    font-family: ${props => props.theme.fonts.sofia};
    color: ${props => props.theme.colours.white};
    font-size: 4rem;
    line-height: 1.25;

    @media (max-width: 1024px) {
      max-width: 100%;
      font-size: 3.5rem;
      line-height: 1.2;
      margin-bottom: 5rem;

      br {
        display: none;
      }
    }

    @media (max-width: 650px) {
      max-width: 100%;
      font-size: 5rem;
      line-height: 1.2;
      margin-bottom: -2rem;

      br {
        display: none;
      }
    }
  }
`

const WrapLeft = styled.div`
  width: 40%;
  font-family: ${props => props.theme.fonts.sofia};
  color: ${props => props.theme.colours.white};
  font-size: 4rem;

  @media (max-width: 650px) {
    max-width: 100%;
    width: 100%;
    height: 100%;
    font-size: 7rem;
    line-height: 1.1;
    margin-bottom: 5rem;

    br {
      display: none;
    }
  }

  ${WrapText} {
    margin-bottom: 5rem;
  }
`

const ButtonGetInTouch = styled.button`
  /* background-color: ${props => props.theme.colours.pink}; */
  background-color: transparent;
  color: ${props => props.theme.colours.white};
  font-family: ${props => props.theme.fonts.circular};
  letter-spacing: 0.1rem;
  font-weight: 700;
  text-transform: uppercase;
  border: 2px solid ${props => props.theme.colours.white};
  border-radius: 50px;
  padding: 20px 50px;
  font-size: 12px;
  pointer-events: inherit;
  cursor: pointer;
  transition: background-color 0.25s ease-in-out, color 0.25s ease-in-out;
  border: 0.25s ease-in-out;
  z-index: 5;

  @media (max-width: 650px) {
    right: 2.5rem;
    width: 25rem;
    font-size: 10px;
    padding: 15px 25px;
    /* border: 0; */
  }

  &:hover {
    background-color: ${props => props.theme.colours.white};
    color: ${props => props.theme.colours.pink};
    border: 2px solid ${props => props.theme.colours.white};

    @media (max-width: 650px) {
      border: 0;
    }
  }
`

const WrapItems = styled.div`
  width: 45%;

  @media (max-width: 650px) {
    width: 100%;
  }

  ${WrapText} {
    width: 100%;
    margin-bottom: 2.5rem;

    @media (max-width: 1024px) {
      margin-bottom: 1rem;
      font-size: 3.5rem;
    }

    @media (max-width: 650px) {
      height: auto;
      font-size: 5rem;
    }
  }
`

const Item = styled.li`
  position: relative;
  width: 100%;
  list-style: none;
  padding-left: 2rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: rgba(0, 0, 0, 0.5);
  color: rgba(0, 0, 0, 0.5);

  &:before {
    content: "•";
    font-size: 4rem;
    line-height: 1.25;
    position: absolute;
    left: -2rem;
    top: 50%;
    transform: translateY(-55%);

    @media (max-width: 650px) {
      font-size: 5.5rem;
      transform: translateY(-60%);
    }
  }

  @media (max-width: 650px) {
    width: 100%;
    margin-top: 1.5rem;
  }

  h3 {
    color: rgba(0, 0, 0, 0.5);
    font-size: 3.2rem;

    @media (max-width: 650px) {
      font-size: 3.5rem;
    }
  }

  p {
    @media (max-width: 650px) {
      font-size: 2.3rem;
      max-width: 100%;
    }
  }
`
