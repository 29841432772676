import React, { useState, useRef, useEffect, useContext } from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import Img from "gatsby-image/withIEPolyfill"
import { useSpring, animated } from "react-spring"
import Observer from "../observer"
import { ScrollbarContext } from "../context/scrollbar-context"

export const fragment = graphql`
  fragment JoinUsOurClientsFragment on WordPress_Page_Joinfields {
    ourClients {
      title
      text
      image {
        sourceUrl
        imageFile {
          childImageSharp {
            fluid(maxWidth: 1000, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
      imageSquare {
        sourceUrl
        imageFile {
          childImageSharp {
            fluid(maxWidth: 1000, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    }
  }
`

const calc = o => `translateY(${o * 0.5}px)`

const OurClients = ({ ourClients }) => {
  const [scrollbar] = useContext(ScrollbarContext)
  const [animatedTitle, setAnimatedTitle] = useState(false)
  const [animatedImage, setAnimatedImage] = useState(false)
  const [isDesktop, setIsDesktop] = useState()
  const ref = useRef()

  const refPara2 = useRef()
  const [{ offset }, set] = useSpring(() => ({ offset: 0 }))

  useEffect(() => {
    const fixedPosition = ({ offset = 0 }) => {
      if (refPara2.current) {
        // wrapRef.current.style.top = `${offset.y}px`
        // refPara1.current.style.transform = `translateY(${offset.y / 5}px)`
        set({ offset: 400 + offset.y / -5 })
      }
    }

    // Hooks into the Smooth Scrollbar instance that's passed as context
    // to create an artificially fixed element, sync to the current
    // Smooth Scrollbar offset
    if (scrollbar) {
      scrollbar.addListener(status => fixedPosition(status))
    }

    return function cleanupListener() {
      if (scrollbar) {
        scrollbar.removeListener(status => fixedPosition(status))
      }
    }
  }, [scrollbar, set])

  useEffect(() => {
    if (typeof window !== `undefined`) {
      setIsDesktop(window.innerWidth > 1024)
    }
  }, [])

  const titleAnimation = useSpring({
    opacity: animatedTitle ? 1 : 0,
    config: { mass: 5, tension: 500, friction: 100 },
  })

  const imageAnimation = useSpring({
    opacity: animatedImage ? 1 : 0,
    config: { mass: 5, tension: 500, friction: 100 },
  })

  return (
    <Outer>
      <Observer
        element={ref.current}
        toggle={animatedTitle}
        setToggle={setAnimatedTitle}
      >
        <Observer
          element={ref.current}
          toggle={animatedImage}
          setToggle={setAnimatedImage}
        >
          <WrapBenefits ref={ref}>
            <Benefit style={titleAnimation} key={ourClients.title}>
              <WrapText>
                <h3 dangerouslySetInnerHTML={{ __html: ourClients.title }} />
                <div dangerouslySetInnerHTML={{ __html: ourClients.text }} />
              </WrapText>
              <WrapImg style={imageAnimation}>
                <div className="square">
                  <Img
                    fluid={
                      ourClients.imageSquare.imageFile.childImageSharp.fluid
                    }
                  />
                </div>
                <animated.div
                  className="vertical"
                  ref={refPara2}
                  style={{
                    transform: offset.interpolate(calc),
                  }}
                >
                  <Img
                    fluid={ourClients.image.imageFile.childImageSharp.fluid}
                  />
                </animated.div>
              </WrapImg>
            </Benefit>
          </WrapBenefits>
        </Observer>
      </Observer>
    </Outer>
  )
}

export default OurClients

const Outer = styled.div`
  position: relative;
  margin: 0 auto;
  padding: 0 10% 18rem 10%;
  display: flex;
  flex-wrap: wrap;
  z-index: 5;

  @media (max-width: 1024px) {
    padding: 14.5rem 7.5% 14.5rem 7.5%;
  }

  @media (max-width: 650px) {
    padding: 0 3.5rem 65px 3.5rem;
  }
`

const WrapBenefits = styled.div`
  width: 100%;
`

const Benefit = styled(animated.div)`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-top: 15rem;

  &:first-child {
    margin-top: 0;
  }

  &:nth-child(2n) {
    flex-direction: row-reverse;
  }

  @media (max-width: 650px) {
    margin-top: 10rem;
    flex-direction: column-reverse;
  }
`

const WrapText = styled.div`
  width: 40%;

  @media (max-width: 650px) {
    padding-top: 5rem;
    width: 100%;
    margin-left: -3.5rem;
  }

  h3 {
    font-size: 5.5rem;
    color: ${props => props.theme.colours.grey};

    @media (max-width: 650px) {
      font-size: 5rem;
      margin-bottom: 3rem;
    }
  }

  p {
    font-size: 1.6rem;
    font-weight: 300;
    line-height: 1.8;
    font-family: ${props => props.theme.fonts.circular};
    color: ${props => props.theme.colours.grey};

    @media (max-width: 650px) {
      font-size: 2.3rem;
      /* margin-bottom: 5rem; */
    }

    &.smallTitle {
      color: ${props => props.theme.colours.pink};
    }
  }
`

const WrapImg = styled(animated.div)`
  width: 50%;

  @media (max-width: 1024px) {
    width: 50%;
  }

  @media (max-width: 650px) {
    width: calc(100% + 3.5rem);
  }

  .vertical {
    width: 75%;
    /* transform: translate(0%, 0%); */
    border-radius: 12px;
    overflow: hidden;
    position: relative;
    will-change: transform;

    @media (max-width: 650px) {
      transform: translateY(-50px) !important;
    }
  }

  .square {
    width: 80%;
    transform: translate(25%, 25%);
  }
`
