import React from 'react'
import { Link } from 'gatsby'
import styled, { css } from 'styled-components'

const Button = ({ type, to, text, click }) => {
  switch (type) {
    case 'link':
      return (
        <ButtonMainLink to={to}>
          <ButtonText>{text}</ButtonText>
        </ButtonMainLink>
      )
    case 'a':
      return (
        <ButtonMainA href={to}>
          <ButtonText>{text}</ButtonText>
        </ButtonMainA>
      )
    case 'button':
      return (
        <ButtonMainButton>
          <ButtonText>{text}</ButtonText>
        </ButtonMainButton>
      )
    default:
      return null
  }
}

const ButtonText = styled.span`
  position: relative;
  font-family: ${props => props.theme.fonts.maison};
  font-weight: 500;
  line-height: 1.2;
  font-size: 1.2rem;
  letter-spacing: 0.5rem;
  text-transform: uppercase;
  z-index: 5;

  @media (max-width: 650px) {
    font-size: 2rem;
  }

  &:before {
    display: none;
  }
`

const buttonStyles = css`
  position: relative;
  display: inline-block;
  text-decoration: none;
  padding: 2rem 2.5rem 1.7rem;
  color: ${props => props.theme.colours.orange};
  border: 2px solid ${props => props.theme.colours.orange};
  text-transform: uppercase;
  cursor: pointer;

  @media (max-width: 1000px) {
    padding: 2rem 7rem;
  }

  &:focus {
    outline: 0;
  }
`

const ButtonMainLink = styled(Link)`
  ${buttonStyles}
`

const ButtonMainA = styled.a`
  ${buttonStyles}
`

const ButtonMainButton = styled.button`
  ${buttonStyles}
`

Button.defaultProps = {
  type: 'link',
  to: '/',
  text: 'find out more',
  click: null
}

export default Button
