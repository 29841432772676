import React, { useState, useRef, useEffect, useContext } from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import Img from "gatsby-image/withIEPolyfill"
import { useSpring, animated } from "react-spring"
import { ScrollbarContext } from "../context/scrollbar-context"
import Observer from "../observer"

export const fragment = graphql`
  fragment JoinUsOurStudioFragment on WordPress_Page_Joinfields {
    ourStudio {
      smallTitle
      title
      text
      image {
        sourceUrl
        imageFile {
          childImageSharp {
            fluid(maxWidth: 1000, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
      imageSquare {
        sourceUrl
        imageFile {
          childImageSharp {
            fluid(maxWidth: 1000, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    }
  }
`

const calc = o => `translateY(${o * 0.5}px)`

const OurStudio = ({ ourStudio }) => {
  const [scrollbar] = useContext(ScrollbarContext)
  const [animatedTitle, setAnimatedTitle] = useState(false)
  const [animatedImage, setAnimatedImage] = useState(false)
  const [isDesktop, setIsDesktop] = useState()
  const ref = useRef()

  const refPara1 = useRef()
  const [{ offset }, set] = useSpring(() => ({ offset: 0 }))

  useEffect(() => {
    const fixedPosition = ({ offset = 0 }) => {
      if (refPara1.current) {
        // wrapRef.current.style.top = `${offset.y}px`
        // refPara1.current.style.transform = `translateY(${offset.y / 5}px)`
        set({ offset: offset.y / 5 })
      }
    }

    // Hooks into the Smooth Scrollbar instance that's passed as context
    // to create an artificially fixed element, sync to the current
    // Smooth Scrollbar offset
    if (scrollbar) {
      scrollbar.addListener(status => fixedPosition(status))
    }

    return function cleanupListener() {
      if (scrollbar) {
        scrollbar.removeListener(status => fixedPosition(status))
      }
    }
  }, [scrollbar, set])

  useEffect(() => {
    if (typeof window !== `undefined`) {
      setIsDesktop(window.innerWidth > 1024)
    }
  }, [])

  const titleAnimation = useSpring({
    opacity: animatedTitle ? 1 : 0,
    config: { mass: 5, tension: 500, friction: 100 },
  })

  const imageAnimation = useSpring({
    opacity: animatedImage ? 1 : 0,
    config: { mass: 5, tension: 500, friction: 100 },
  })

  return (
    <Outer>
      <Observer
        element={ref.current}
        toggle={animatedTitle}
        setToggle={setAnimatedTitle}
      >
        <Observer
          element={ref.current}
          toggle={animatedImage}
          setToggle={setAnimatedImage}
        >
          <WrapBenefits ref={ref}>
            <Benefit style={titleAnimation} key={ourStudio.title}>
              <WrapImg style={imageAnimation}>
                <div className="wrapImages">
                  <animated.div
                    className="square"
                    ref={refPara1}
                    style={{
                      transform: offset.interpolate(calc),
                    }}
                  >
                    <Img
                      fluid={
                        ourStudio.imageSquare.imageFile.childImageSharp.fluid
                      }
                    />
                  </animated.div>
                  <div className="vertical">
                    <Img
                      fluid={ourStudio.image.imageFile.childImageSharp.fluid}
                    />
                  </div>
                </div>
              </WrapImg>
              <WrapText>
                <p className="smallTitle">{ourStudio.smallTitle}</p>
                <h3 dangerouslySetInnerHTML={{ __html: ourStudio.title }} />
                <div dangerouslySetInnerHTML={{ __html: ourStudio.text }} />
              </WrapText>
            </Benefit>
          </WrapBenefits>
        </Observer>
      </Observer>
    </Outer>
  )
}

export default OurStudio

const Outer = styled.div`
  position: relative;
  margin: 0 auto;
  padding: 0 10% 18rem 10%;
  display: flex;
  flex-wrap: wrap;
  z-index: 5;

  @media (max-width: 1024px) {
    padding: 14.5rem 7.5% 14.5rem 7.5%;
  }

  @media (max-width: 650px) {
    padding: 10rem 3.5rem 0 3.5rem;
  }
`

const WrapBenefits = styled.div`
  width: 100%;
`

const Benefit = styled(animated.div)`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-top: 15rem;

  &:first-child {
    margin-top: 0;
  }

  &:nth-child(2n) {
    flex-direction: row-reverse;
  }

  @media (max-width: 650px) {
    margin-top: 10rem;
  }
`

const WrapText = styled.div`
  width: 40%;

  @media (max-width: 650px) {
    width: 100%;
    /* padding-top: 10rem; */
  }

  h3 {
    font-size: 5.5rem;
    color: ${props => props.theme.colours.grey};

    @media (max-width: 650px) {
      font-size: 5rem;
      margin-bottom: 3rem;
    }
  }

  p {
    font-size: 1.6rem;
    font-weight: 300;
    line-height: 1.8;
    font-family: ${props => props.theme.fonts.circular};
    color: ${props => props.theme.colours.grey};

    @media (max-width: 650px) {
      font-size: 2.25rem;
      line-height: 1.66;
      /* margin-bottom: 5rem; */
    }

    &.smallTitle {
      letter-spacing: 0.2rem;
      color: ${props => props.theme.colours.pink};

      @media (max-width: 650px) {
        font-size: 1.6rem;
        margin-bottom: 2rem;
      }
    }
  }
`

const WrapImg = styled(animated.div)`
  width: 50%;

  @media (max-width: 1024px) {
    width: 50%;
  }

  @media (max-width: 650px) {
    width: calc(100% + 3.5rem);
    transform: translate(-3.6rem, 75px);

    div {
      overflow: visible !important;
    }

    img {
      width: calc(100% + 3.5rem) !important;
      max-width: calc(100% + 3.5rem) !important;
    }
  }

  .vertical {
    width: 55%;
    transform: translate(80%, -25%);

    @media (max-width: 650px) {
      transform: translate(80%, -150px) !important;
    }
  }

  .square {
    width: 80%;
    /* transform: translateY(25%); */
    will-change: transform;
  }
`
