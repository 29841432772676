import React, { useState, useContext, useRef } from "react"
import { graphql, Link, navigate } from "gatsby"
import styled from "styled-components"
import { useSpring, animated } from "react-spring"
import Observer from "../observer"
import { TransitionContext } from "../context/transition-context"

export const fragmentQl = graphql`
  fragment JoinUsIntroFragment on WordPress_Page_Joinfields {
    introInfos {
      preTitle
      title
      text
    }
  }
`

const JoinUsIntro = ({ intro }) => {
  // eslint-disable-next-line no-unused-vars
  const [transitionActive, setTransitionActive] = useContext(TransitionContext)
  const [animatedPreTitle, setAnimatedPreTitle] = useState(false)
  const [animatedTitle, setAnimatedTitle] = useState(false)
  const [animatedContent, setAnimatedContent] = useState(false)
  const pretitleRef = useRef()
  const titleRef = useRef()
  const contentRef = useRef()

  const pretitleAnimation = useSpring({
    transform: animatedTitle ? `translate(0px,0px)` : `translate(0,50px)`,
    opacity: animatedTitle ? 1 : 0,
    config: { mass: 5, tension: 500, friction: 100 },
  })

  const titleAnimation = useSpring({
    transform: animatedTitle ? `translate(0px,0px)` : `translate(0,50px)`,
    opacity: animatedTitle ? 1 : 0,
    config: { mass: 5, tension: 500, friction: 100 },
  })

  const contentAnimation = useSpring({
    transform: animatedContent ? `translate(0px,0px)` : `translate(0,50px)`,
    opacity: animatedContent ? 1 : 0,
    config: { mass: 5, tension: 500, friction: 100 },
  })

  return (
    <Outer>
      <WrapIntro>
        <WrapText>
          <Observer
            element={pretitleRef.current}
            toggle={pretitleAnimation}
            setToggle={setAnimatedPreTitle}
          >
            <IntroPreTitle
              style={titleAnimation}
              ref={titleRef}
              dangerouslySetInnerHTML={{ __html: intro.preTitle }}
            />
          </Observer>
          <Observer
            element={titleRef.current}
            toggle={animatedTitle}
            setToggle={setAnimatedTitle}
          >
            <IntroTitle
              style={titleAnimation}
              ref={titleRef}
              dangerouslySetInnerHTML={{ __html: intro.title }}
            />
          </Observer>
          <Observer
            element={contentRef.current}
            toggle={animatedContent}
            setToggle={setAnimatedContent}
          >
            <IntroText
              style={contentAnimation}
              ref={contentRef}
              dangerouslySetInnerHTML={{ __html: intro.text }}
            />
          </Observer>
        </WrapText>
      </WrapIntro>
    </Outer>
  )
}

export default JoinUsIntro

const Outer = styled.section`
  position: relative;
  height: auto;
`

const WrapIntro = styled.div`
  position: relative;
  height: 100%;
`

const WrapText = styled.div`
  height: auto;
  width: 75%;
  margin: 0 auto 0 12.5%;
  padding: 17.5rem 0;

  @media (min-width: 2000px) {
    height: auto;
    width: 40%;
    margin: 0 auto 0 15%;
    padding: 17.5rem 0;
  }

  @media (max-width: 1024px) {
    width: 70%;
    margin: 0 10%;
    position: relative;
    padding: 7.5rem 0;
  }

  @media (max-width: 650px) {
    width: 100%;
    margin: 0 auto;
    position: relative;
    padding: 5rem 3rem 0 3rem;
  }
`

const IntroPreTitle = styled(animated.p)`
  color: ${props => props.theme.colours.pink};
  font-family: ${props => props.theme.fonts.sofia};
  font-size: 4.5rem;
  line-height: 1;
  letter-spacing: -0.075rem;
  /* margin-bottom: 3rem; */
  margin-bottom: 0;

  @media (max-width: 1024px) {
    font-size: 5.5rem;
    width: 70%;
    margin-bottom: 2rem;
  }

  @media (max-width: 650px) {
    width: 100%;
    font-size: 4.25rem;
  }
`

const IntroTitle = styled(animated.h1)`
  p {
    color: ${props => props.theme.colours.grey};
    font-family: ${props => props.theme.fonts.sofia};
    font-size: 7rem;
    line-height: 1.15;
    letter-spacing: -0.075rem;
    margin-bottom: 8.5rem;
    max-width: 21ch;

    @media (max-width: 1024px) {
      font-size: 5.5rem;
      width: 100%;
      margin-bottom: 4rem;
    }

    @media (max-width: 650px) {
      width: 100%;
      font-size: 7rem;
    }
  }
`

const IntroText = styled(animated.div)`
  width: 55%;
  margin: 0 auto 0 40%;

  @media (max-width: 1024px) {
    width: 80%;
    margin: 0 auto 0 30%;
  }

  @media (max-width: 650px) {
    width: 92.5%;
    margin: 0;
  }

  p {
    color: ${props => props.theme.colours.grey};
    font-family: ${props => props.theme.fonts.circular};
    font-weight: 300;
    font-size: 1.6rem;
    line-height: 1.9;

    @media (max-width: 1024px) {
      font-size: 2rem;
      line-height: 1.8;
    }

    @media (max-width: 650px) {
      font-size: 2.25rem;
      line-height: 1.8;
      margin-bottom: 0;
    }
  }
`
