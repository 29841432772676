import React, { useState, useRef, useEffect } from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import Img from "gatsby-image/withIEPolyfill"
import { useSpring, animated } from "react-spring"
import Observer from "../observer"

import JobItemComponent from "./jobItem"

import Triangle from "../../images/filter_triangle.svg"

export const fragment = graphql`
  fragment JoinUsCurrentJobsFragment on WordPress_Page_Joinfields {
    currentOpportunities {
      jobs {
        ... on WordPress_Job {
          id
          title
          jobFields {
            fieldGroupName
            jobTitle
            jobDescription
            jobRequirements {
              item
            }
          }
        }
      }
      title
    }
  }
`

const CurrentJobs = ({ currentJobs }) => {
  const [animatedTitle, setAnimatedTitle] = useState(false)
  const [animatedImage, setAnimatedImage] = useState(false)
  const [isDesktop, setIsDesktop] = useState()
  const [on, toggle] = useState(false)
  const ref = useRef()

  const animation = useSpring({
    overflow: "hidden",
    maxHeight: on ? "1200px" : "0px",
    marginBottom: on ? "60px" : "0px",
  })

  const animationTriangle = useSpring({
    transform: on ? "rotate(180deg)" : "rotate(0deg)",
  })

  useEffect(() => {
    if (typeof window !== `undefined`) {
      setIsDesktop(window.innerWidth > 1024)
    }
  }, [])

  const titleAnimation = useSpring({
    opacity: animatedTitle ? 1 : 0,
    config: { mass: 5, tension: 500, friction: 100 },
  })

  const imageAnimation = useSpring({
    opacity: animatedImage ? 1 : 0,
    config: { mass: 5, tension: 500, friction: 100 },
  })

  return (
    <Outer>
      <Observer
        element={ref.current}
        toggle={animatedTitle}
        setToggle={setAnimatedTitle}
      >
        <Observer
          element={ref.current}
          toggle={animatedImage}
          setToggle={setAnimatedImage}
        >
          <WrapOpportunities ref={ref}>
            <WrapText>
              <h3 dangerouslySetInnerHTML={{ __html: currentJobs.title }} />
            </WrapText>
            {currentJobs.jobs.map((job, index) => (
              <JobItemComponent job={job} key={job.id} />
            ))}
          </WrapOpportunities>
        </Observer>
      </Observer>
    </Outer>
  )
}

export default CurrentJobs

const Outer = styled.div`
  position: relative;
  margin: 0 auto;
  padding: 14.5rem 10%;
  display: flex;
  flex-wrap: wrap;
  z-index: 5;
  background-color: ${props => props.theme.colours.grey};

  @media (max-width: 1024px) {
    padding: 14.5rem 7.5% 14.5rem 7.5%;
  }

  @media (max-width: 650px) {
    padding: 12rem 3.5rem 15rem 3.5rem;
  }
`

const WrapOpportunities = styled.div`
  width: 100%;
`

const WrapText = styled.div`
  width: 80%;
  /* max-width: 14ch; */

  @media (max-width: 650px) {
    width: 100%;
  }

  h3 {
    font-size: 5.5rem;
    color: ${props => props.theme.colours.pink};
    margin-bottom: 10rem;
    max-width: 15ch;

    @media (max-width: 650px) {
      font-size: 5rem;
      margin-bottom: 3rem;
    }
  }

  p {
    font-size: 1.6rem;
    font-weight: 300;
    line-height: 1.8;
    font-family: ${props => props.theme.fonts.circular};
    color: ${props => props.theme.colours.grey};

    @media (max-width: 650px) {
      font-size: 2.3rem;
      margin-bottom: 5rem;
    }

    &.smallTitle {
      color: ${props => props.theme.colours.white};
    }
  }
`
