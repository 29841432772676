import React, { useState, useEffect, useLayoutEffect } from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import JoinUsHero from "../components/join-us/hero"
import JoinUsIntro from "../components/join-us/intro"
import OurStudio from "../components/join-us/ourStudio"
import OurClients from "../components/join-us/ourClients"
import CurrentJobs from "../components/join-us/currentJobs"
import JoinUsInterested from "../components/join-us/interested"
import SEO from "../components/seo"

const JoinUs = ({ data, pageContext }) => {
  const [isDesktop, setIsDesktop] = useState(null)

  function handleResize() {
    setIsDesktop(window.innerWidth > 1024)
  }

  useEffect(() => {
    if (typeof window !== `undefined`) {
      setIsDesktop(window.innerWidth > 1024)
    }
  }, [])

  useLayoutEffect(() => {
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [isDesktop])

  return (
    <Layout>
      <SEO
        pageTitle={pageContext.title}
        slug={pageContext.slug}
        {...data.wordPress.pageBy.seo}
      />
      <JoinUsHero hero={data.wordPress.pageBy.joinFields.heroInfos} />
      <JoinUsIntro intro={data.wordPress.pageBy.joinFields.introInfos} />
      <OurStudio ourStudio={data.wordPress.pageBy.joinFields.ourStudio} />
      <OurClients ourClients={data.wordPress.pageBy.joinFields.ourClients} />
      <CurrentJobs
        currentJobs={data.wordPress.pageBy.joinFields.currentOpportunities}
      />
      <JoinUsInterested
        interested={data.wordPress.pageBy.joinFields.interestedIn}
      />
    </Layout>
  )
}

export default JoinUs

export const query = graphql`
  query($wordpressId: Int!) {
    wordPress {
      pageBy(pageId: $wordpressId) {
        ...SeoFragment
        joinFields {
          ...JoinusHeroFragment
          ...JoinUsIntroFragment
          ...JoinUsOurStudioFragment
          ...JoinUsOurClientsFragment
          ...JoinUsCurrentJobsFragment
          ...JoinUsInterestedFragment
        }
      }
    }
  }
`
