import React from "react"
import styled from "styled-components"

const Checkbox = ({ className, checked, text, ...props }) => (
  <CheckboxContainer className={className}>
    <HiddenCheckbox checked={checked} {...props} />
    <StyledCheckbox checked={checked}>
      <Icon viewBox="0 0 24 24">
        <polyline points="20 6 9 17 4 12" />
      </Icon>
    </StyledCheckbox>
    <StyledLabel>{text}</StyledLabel>
  </CheckboxContainer>
)

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0.5rem;

  @media (max-width: 1024px) {
    margin: 3.5rem 0 1rem 0;
    align-items: flex-start;
  }
`

const StyledLabel = styled.p`
  font-family: ${props => props.theme.fonts.roboto};
  font-size: 1.2rem;
  line-height: 1.3;
  color: rgba(255, 255, 255, 0.8);
  margin: 0;
  -webkit-font-smoothing: auto;
  color: ${props => props.theme.colours.grey};

  @media (max-width: 1024px) {
    font-size: 1.8rem;
  }
`

const Icon = styled.svg`
  fill: none;
  stroke: ${props => props.theme.colours.primary};
  stroke-width: 0.3rem;
`
// Hide checkbox visually but remain accessible to screen readers.
// Source: https://polished.js.org/docs/#hidevisually
const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 0.1rem;
`

const StyledCheckbox = styled.div`
  display: inline-block;
  width: 1.8rem;
  height: 1.8rem;
  background: ${props =>
    props.checked ? props.theme.colours.pink : "transparent"};
  border: 0.1rem solid ${props => props.theme.colours.grey};
  border-radius: 50%;
  transition: background 150ms;
  flex-shrink: 0;
  margin-right: 2rem;
  ${Icon} {
    visibility: ${props => (props.checked ? "visible" : "hidden")};
  }
`

export default Checkbox
