import React, { useState, useRef, useEffect } from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import usePortal from "react-useportal"
import { useSpring, animated } from "react-spring"

import BezierEasing from "bezier-easing"

import CrossSvg from "../../images/cross.svg"
import JobForm from "./form"

import Triangle from "../../images/filter_triangle.svg"

export const fragment = graphql`
  fragment JoinUsCurrentJobsFragment on WordPress_Page_Joinfields {
    currentOpportunities {
      jobs {
        ... on WordPress_Job {
          id
          title
          jobFields {
            fieldGroupName
            jobTitle
            jobDescription
            jobRequirements {
              item
            }
          }
        }
      }
      title
    }
  }
`

const JobItemComponent = ({ job }) => {
  const [animatedTitle, setAnimatedTitle] = useState(false)
  const [animatedImage, setAnimatedImage] = useState(false)
  const [isDesktop, setIsDesktop] = useState()
  const [on, toggle] = useState(false)
  const ref = useRef()
  const descRef = useRef()
  const [modalJob, setModalJob] = useState(null)

  const { openPortal, closePortal, isOpen, Portal } = usePortal({
    onOpen({ portal }) {
      portal.current.style.cssText = `
        position: absolute;
        height: 100vh;
        overflow: visible;
        z-index: 1000;
      `
    },
  })

  const animation = useSpring({
    // overflow: "hidden",
    // // maxHeight: on ? "1500px" : "0px",
    // maxHeight: on ? `${descRef.current.offsetHeight}px` : "0",
    // marginBottom: on ? "60px" : "0px",
    // config: {
    //   duration: 1000,
    //   // easing: easings.easeInOutQuart,
    //   easing: BezierEasing(0.76, 0, 0.24, 1),
    // },
    overflow: "hidden",
    maxHeight: on ? `${descRef.current.offsetHeight}px` : "0px",
    config: {
      duration: 750,
      easing: BezierEasing(0.37, 0, 0.63, 1),
    },
  })

  const animationTriangle = useSpring({
    transform: on ? "rotate(180deg)" : "rotate(0deg)",
  })

  useEffect(() => {
    if (typeof window !== `undefined`) {
      setIsDesktop(window.innerWidth > 1024)
    }
    console.log(descRef.current.offsetHeight)
  }, [])

  return (
    <JobItem key={job.id}>
      <WrapJobTitle onClick={() => toggle(!on)}>
        <JobTitle>{job.jobFields.jobTitle}</JobTitle>
        <MoreWrap>
          <MoreDetailSpan>{on ? "Less" : "More"} Details </MoreDetailSpan>
          <animated.span style={animationTriangle}>
            <Triangle />
          </animated.span>
        </MoreWrap>
      </WrapJobTitle>
      <HiddenDropdown style={animation}>
        {/* <HiddenDropdown style={{ overflow: "hidden", ...props }}> */}
        <WrapIt ref={descRef}>
          <JobDescription
            dangerouslySetInnerHTML={{
              __html: job.jobFields.jobDescription,
            }}
          />
          {job.jobFields.jobRequirements && (
            <WrapRequirements>
              <ul>
                {job.jobFields.jobRequirements.map((requirement, index) => (
                  <li key={requirement.item}>{requirement.item}</li>
                ))}
              </ul>
            </WrapRequirements>
          )}
          <ButtonGetInTouch onClick={openPortal}>Apply Now</ButtonGetInTouch>
        </WrapIt>
      </HiddenDropdown>
      <Portal>
        <Modal className={isOpen ? "active" : ""}>
          <ModalContents>
            <Close onClick={closePortal}>
              <CrossSvg />
            </Close>

            <WrapContent>
              <WrapLeft>
                <Title>Apply Now</Title>
                <SmallTitle>Role</SmallTitle>
                <JobTitleModal>{job.jobFields.jobTitle}</JobTitleModal>
                <SubmitInfos>
                  Please fill in the form including your CV or link to your
                  portfolio and we will get back to you.
                </SubmitInfos>
                <SubmitInfosWarning>
                  Please note, we don't ever reply to recruiters.
                </SubmitInfosWarning>
              </WrapLeft>

              <WrapRight>
                <InsideWrap>
                  <JobForm jobTitle={job.jobFields.jobTitle} />
                </InsideWrap>
              </WrapRight>
            </WrapContent>
          </ModalContents>
          <ModalBackground />
        </Modal>
      </Portal>
    </JobItem>
  )
}

export default JobItemComponent

const JobItem = styled.div`
  /* margin: 0.5rem 0; */
  /* border-bottom: 1px solid ${props => props.theme.colours.white}; */
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  /* padding: 0 0 4.5rem 0; */
  
  &:first-child {
    /* border-top: 1px solid ${props => props.theme.colours.white}; */
    border-top: 1px solid rgba(255, 255, 255, 0.3);
  }
`

const HiddenDropdown = styled(animated.div)`
  color: ${props => props.theme.colours.white};
`

const ButtonGetInTouch = styled.button`
  /* background-color: ${props => props.theme.colours.pink}; */
  background-color: transparent;
  color: ${props => props.theme.colours.pink};
  font-family: ${props => props.theme.fonts.circular};
  letter-spacing: 0.1rem;
  font-weight: 700;
  text-transform: uppercase;
  border: 2px solid ${props => props.theme.colours.pink};
  border-radius: 50px;
  padding: 20px 50px;
  font-size: 12px;
  pointer-events: inherit;
  cursor: pointer;
  transition: background-color 0.25s ease-in-out, color 0.25s ease-in-out;
  border: 0.25s ease-in-out;
  z-index: 5;

  @media (max-width: 650px) {
    right: 2.5rem;
    width: 25rem;
    font-size: 10px;
    padding: 15px 25px;
    /* border: 0; */
  }

  &:hover {
    background-color: ${props => props.theme.colours.white};
    color: ${props => props.theme.colours.pink};
    border: 2px solid ${props => props.theme.colours.white};

    @media (max-width: 650px) {
      border: 0;
    }
  }
`

const WrapIt = styled.div`
  padding-bottom: 60px;
`

const JobDescription = styled.div`
  max-width: 75%;
  margin-bottom: 4rem;

  @media (max-width: 650px) {
    max-width: 100%;
  }

  p {
    color: ${props => props.theme.colours.white};

    @media (max-width: 650px) {
      font-size: 2.2rem;
    }
  }

  ul {
    list-style: none;
    width: 100%;
  }

  li {
    position: relative;
    font-size: 1.6rem;
    line-height: 1.8;
    font-family: ${props => props.theme.fonts.circular};
    padding-left: 3rem;

    @media (max-width: 650px) {
      font-size: 2.2rem;
    }

    &:before {
      position: absolute;
      top: 8px;
      left: -1rem;
      content: "";
      width: 1.3rem;
      height: 1.3rem;
      background-color: ${props => props.theme.colours.pink};
      border-radius: 50%;
    }
  }
`

const WrapRequirements = styled.div`
  max-width: 75%;
  margin-bottom: 4rem;

  @media (max-width: 650px) {
    max-width: 100%;
  }

  ul {
    list-style: none;
    width: 100%;
  }

  li {
    position: relative;
    font-size: 1.6rem;
    line-height: 1.8;
    font-family: ${props => props.theme.fonts.circular};
    padding-left: 3rem;

    @media (max-width: 650px) {
      font-size: 2.2rem;
    }

    &:before {
      position: absolute;
      top: 8px;
      left: -1rem;
      content: "";
      width: 1.3rem;
      height: 1.3rem;
      background-color: ${props => props.theme.colours.pink};
      border-radius: 50%;
    }
  }
`

const WrapJobTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 5rem 0;
`

const JobTitle = styled.h3`
  font-size: 4rem;
  color: ${props => props.theme.colours.white};
  margin-bottom: 0;
`

const MoreDetailSpan = styled.span``

const MoreWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props => props.theme.colours.pink};
  font-size: 1.2rem;
  letter-spacing: 0.2rem;
  text-transform: uppercase;

  span {
    line-height: 0;
    display: inline-block;
    margin-left: 1rem;

    @media (max-width: 650px) {
      svg {
        width: 2.5rem;
        height: 2.5rem;
      }
    }
  }

  & ${MoreDetailSpan} {
    display: block;

    @media (max-width: 650px) {
      display: none;
    }
  }
`

const ModalContents = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: scale(0.9);
  transition: transform 0.3s ease;
  overflow: hidden;
  z-index: 5;
`

const ModalBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 1;
`

const Modal = styled(animated.aside)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease;
  z-index: 100;
  overflow: hidden;

  @media (max-width: 650px) {
    height: 100vh;
  }

  &.active {
    opacity: 1;
    pointer-events: all;

    ${ModalContents} {
      transform: scale(1);
    }
  }
`

const Close = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  padding: 30px;
  outline: 0;
  background: none;
  border: none;
  cursor: pointer;
  z-index: 10;
  svg {
    width: 35px;
    height: 35px;
  }
`

const WrapLeft = styled.div`
  width: 50%;
  padding-right: 10rem;

  @media (max-width: 1024px) {
    width: 100%;
    margin: 15rem 15% 2.5rem 15%;
    padding-right: 2rem;
  }

  @media (max-width: 650px) {
    width: 100%;
    margin: 15rem 0 5rem 0;
    padding-right: 0;
  }

  a {
    display: block;
    font-family: ${props => props.theme.fonts.sofia};
    color: ${props => props.theme.colours.pink};
    font-size: 3.4rem;

    @media (max-width: 1024px) {
      font-size: 5rem;
    }

    @media (max-width: 650px) {
      font-size: 3.8rem;
    }
  }
`

const JobTitleModal = styled.h3`
  font-family: ${props => props.theme.fonts.sofia};
  color: ${props => props.theme.colours.grey};
  font-size: 4rem;
  margin-bottom: 3rem;
`

const SmallTitle = styled.h3`
  font-family: ${props => props.theme.fonts.circular};
  color: ${props => props.theme.colours.pink};
  font-size: 1.4rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.2rem;
`

const SubmitInfos = styled.p`
  font-family: ${props => props.theme.fonts.circular};
  color: ${props => props.theme.colours.grey};
  font-size: 2rem;
  margin-bottom: 2rem;

  @media (max-width: 650px) {
    font-size: 2.5rem;
    max-width: 30ch;
  }
`

const SubmitInfosWarning = styled.p`
  font-family: ${props => props.theme.fonts.circular};
  color: ${props => props.theme.colours.pink};
  font-size: 1.6rem;

  @media (max-width: 650px) {
    font-size: 2.5rem;
  }
`

const Title = styled.h2`
  width: 80%;
  font-family: ${props => props.theme.fonts.sofia};
  font-size: 4.5rem;
  color: ${props => props.theme.colours.pink};
  margin-bottom: 3rem;

  @media (max-width: 1024px) {
    width: 100%;
    font-size: 4.5rem;
  }

  @media (max-width: 650px) {
    width: 100%;
  }
`

const InsideWrap = styled.div`
  width: 100%;
  height: 100%;
  margin-left: 10rem;

  @media (max-width: 1024px) {
    margin-left: 0;
  }
`

const WrapRight = styled.div`
  position: relative;
  width: 50%;

  @media (max-width: 1024px) {
    padding: 5rem 15% 15rem 15%;
    width: 100%;
  }

  @media (max-width: 650px) {
    padding: 2.5rem 0 15rem 0;
    width: 100%;
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 1px;
    height: 100%;
    background-color: ${props => props.theme.colours.grey};
    margin-right: 100px;
    display: block;

    @media (max-width: 1024px) {
      width: 100%;
      height: 1px;
      display: none;
    }

    @media (max-width: 650px) {
      width: 100%;
      height: 1px;
      display: block;
    }
  }

  h2 {
    color: ${props => props.theme.colours.grey};
    font-family: ${props => props.theme.fonts.sofia};
    font-size: 45px;

    @media (max-width: 1024px) {
      font-size: 5.5rem;
    }

    @media (max-width: 650px) {
      font-size: 30px;
    }
  }
`

const WrapContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 85%;
  height: 85%;
  overflow-y: scroll;
  margin: 0 auto;
  padding: 3rem 0;

  @media (max-width: 1660px) and (max-height: 740px) {
    width: 85%;
    height: 95%;
    overflow-y: scroll;
    scrollbar-width: none;
    padding: 3rem;
  }

  @media (max-width: 1320px) and (max-height: 675px) {
    width: 85%;
    height: 90%;
    overflow-y: scroll;
    scrollbar-width: none;
    padding: 0 3rem;
  }

  @media (max-width: 800px) {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    scrollbar-width: none;
    position: relative;
    margin: 0 auto;
    padding: 3rem;
  }

  &::-webkit-scrollbar {
    width: 0 !important;
  }
`
